// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/PBSSans.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/PBSSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/calibri.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/icon?family=Material+Icons);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n    --primary: #2639c4;\n    --hover: rgba(38, 57, 196, 10%);\n    --secondary: #9e9e9e;\n    --shadow: #00000033;\n}\n\nbody {\n    margin: 0;\n    font-family: 'Calibri';\n}\n\n.App {\n    max-width: 500px;\n    margin: 0 auto;\n    position: relative;\n    padding-bottom: 60px;\n}\n\n@font-face {\n    font-family: 'PBSSans';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n    font-family: 'PBSSans-Bold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n    font-family: 'Calibri';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}", "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,+BAA+B;IAC/B,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;IACtB,4CAAuC;AAC3C;;AAEA;IACI,2BAA2B;IAC3B,4CAA4C;AAChD;;AAEA;IACI,sBAAsB;IACtB,4CAAuC;AAC3C","sourcesContent":["@import url('https://fonts.googleapis.com/icon?family=Material+Icons');\n\n:root {\n    --primary: #2639c4;\n    --hover: rgba(38, 57, 196, 10%);\n    --secondary: #9e9e9e;\n    --shadow: #00000033;\n}\n\nbody {\n    margin: 0;\n    font-family: 'Calibri';\n}\n\n.App {\n    max-width: 500px;\n    margin: 0 auto;\n    position: relative;\n    padding-bottom: 60px;\n}\n\n@font-face {\n    font-family: 'PBSSans';\n    src: url('../assets/fonts/PBSSans.ttf');\n}\n\n@font-face {\n    font-family: 'PBSSans-Bold';\n    src: url('../assets/fonts/PBSSans-Bold.ttf');\n}\n\n@font-face {\n    font-family: 'Calibri';\n    src: url('../assets/fonts/calibri.ttf');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
